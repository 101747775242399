/* BUILD: postcss edits.css --use autoprefixer cssnano -o edits.min.css */;

@font-face {
    font-family: 'Antonio';
    src: url('fonts/antonio-regular-webfont.woff2') format('woff2'),
         url('fonts/antonio-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Antonio';
    src: url('fonts/antonio-bold-webfont.woff2') format('woff2'),
         url('fonts/antonio-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

.group::after{
	content: "";
	display: table;
	clear: both;
}

.is-weddings .right-header .contact-section, .is-weddings .right-header .menu-section #main-menu{
	display: none;
}

.is-weddings .mobile-menu-wrapper{
	display: block;
}

.is-weddings .mobile-menu {
    background-color: rgba(1, 10, 17, 0.98);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
}
.is-weddings .mobile-menu .close-mobile-menu {
    background: rgba(0, 0, 0, 0) url("../images/cross.png") repeat scroll 0 0;
    cursor: pointer;
    display: block;
    height: 24px;
    overflow: hidden;
    position: absolute;
    right: 20px;
    text-indent: -999px;
    top: 15px;
    width: 24px;
}
.is-weddings .mobile-menu ul#menu {
    margin: 45px 0 0;
    padding: 15px;
}
.is-weddings .mobile-menu ul#menu li {
    border-bottom: 1px solid #333;
    list-style: outside none none;
    padding: 22px 0;
    text-align: center;
}
.is-weddings .mobile-menu ul#menu li a {
    color: #fff;
    font-family: "montserratbold";
    font-size: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}
.is-weddings .mobile-menu ul#menu li a:hover{
	color: #19a39d;
}

.is-weddings .responsive-menu-btn {
    cursor: pointer;
    display: block;
    height: 30px;
    position: absolute;
    right: 20px;
    top: 74px;
}

.is-weddings .responsive-menu-btn .bar {
    background: #fff;
    height: 5px;
    position: relative;
    top: 10px;
    width: 30px;
}

.is-weddings .responsive-menu-btn .bar::after, .is-weddings .responsive-menu-btn .bar::before {
    background: #fff;
    content: "";
    display: block;
    height: 5px;
    position: absolute;
    width: 30px;
    z-index: 2;
}

.is-weddings .responsive-menu-btn .bar::before {
	top: -10px;
}

.is-weddings .responsive-menu-btn .bar::after {
	top: 10px;
}

h3 em{
	font-family: 'Playfair Display', serif;
	text-transform: none;
	font-weight: normal;
	font-size: 22px;
	line-height: 28px;
	padding-bottom: 10px;
}

@media screen and (min-width: 580px){
	h3 em{
		font-size: 26px;
		line-height: 32px;
	}
}

.btn{
	font-family: 'Antonio', sans-serif;
	text-transform: uppercase;
	color: white;
	border-radius: 30px;
	padding: 18px 60px 15px 60px;
	display: inline-block;
	font-size: 16px;
	line-height: 16px;
	background: #19a39d;
	font-weight: bold;
	border: 0;
	cursor: pointer;
}

.btn:hover{
	background: #5ebfbb;
}

.btn.is-sending{
	opacity: 0.6;
	pointer-events: none;
}

.reservation-form{
	margin-top: 30px;
}

@media screen and (min-width: 580px){
	.reservation-form{
		margin-top: 80px;
	}
}

.wedding-form textarea {
    display: inline-block;
    width: 100%;
    border: none;
    background-color: transparent;
    font-family: 'Antonio', sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: inherit;
    -webkit-transition: all linear .3s;
    transition: all linear .3s;
    outline: 0;
    min-height: 100px;
    padding: 5px 0px 10px 0px;
}

.wedding-form input, .wedding-form select{
	font-family: 'Antonio', sans-serif;
	font-size: 18px;
    line-height: 22px;
    padding: 5px 0px 10px 0px;
}

.wedding-form .input-wrapper{
	padding-bottom: 0;
}

.wedding-form label{
	color: black;
	text-transform: none;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: bold;
}

.wedding-section{
	background: white;
	padding: 30px 15px;
	font-family: 'Open Sans', sans-serif;
	box-sizing: border-box;
}

@media screen and (min-width: 580px){
	.wedding-section{
		padding: 60px 20px;
	}
}

.wedding-section.is-alt{
	background: #f5f5f5;
}

.wedding-section *{
	box-sizing: border-box;
}

.wedding-section .inner{
	margin: 0 auto;
	position: relative;
	width: 100%;
}

.wedding-section figure{
	margin: 0;
	padding: 0;
}

.wedding-title{
	color: black;
	margin: 0 0 20px 0;
	text-transform: none;
	text-align: center;
	padding-bottom: 15px;
	position: relative;
	display: block;
	font-family: 'Antonio', 'Montserrat', sans-serif;
	font-size: 28px;
	line-height: 30px;
}

@media screen and (min-width: 560px){
	.wedding-title.is-left{
		text-align: left;
	}
}

.wedding-title::after{
	content: "";
	position: absolute;
	bottom: 0;
	height: 1px;
	width: 50px;
	background: #19a39d;
	left: 50%;
	transform: translate3d(-50%,0,0);
}

@media screen and (min-width: 480px){
	.wedding-title{
		font-size: 36px;
		line-height: 40px;
		padding-bottom: 20px;
		margin: 0 0 30px 0;
	}
}

@media screen and (min-width: 560px){
	.wedding-title.is-left::after{
		left: 0;
		transform: none;
	}
}

.is-weddings header{
	background: transparent;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
}

.wedding-hero, .wedding-hero .owl-carousel, .wedding-hero .owl-stage{
	position: relative;
	height: 100vh;
}

.wedding-hero .item{
	position: relative;
	height: 100vh;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	z-index: 1;
}

.wedding-hero .owl-dots{
	bottom: 60px;
}

@media screen and (min-width: 630px){
	.wedding-hero .owl-dots{
		bottom: 110px;
	}
}

.owl-dots{
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center;
	z-index: 5;
}

.owl-dot{
	display: inline-block;
	margin: 0px 5px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 1px solid #19a39d;
}

.owl-dot.active{
	background: #19a39d;
}

.wedding-hero h1{
	font-family: 'Playfair Display', serif;
	font-style: italic;
	font-weight: normal;
	color: white;
	font-size: 40px;
	line-height: 50px;
	border: 2px solid white;
	display: inline-block;
	text-transform: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	padding: 5px 30px 15px 30px;
	margin: -40px 0 0 0;
	z-index: 10;
}

@media screen and (min-width: 940px){
	.wedding-hero h1{
		font-size: 70px;
		line-height: 80px;
		padding: 7px 50px 20px 50px;
		border: 2px solid white;
	}
}

@media screen and (min-width: 940px){
	.wedding-hero h1{
		font-size: 100px;
		line-height: 120px;
		padding: 8px 70px 25px 70px;
		border: 4px solid white;
	}
}

@media screen and (min-width: 1360px){
	.wedding-hero h1{
		font-size: 170px;
		line-height: 200px;
		padding: 10px 100px 30px 100px;
	}
}

.wedding-nav{
	background: #19a39d;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 5;
	backface-visibility: hidden;
	transform: translate3d(0,0,0);
	display: none;
}

@media screen and (min-width: 630px){
	.wedding-nav{
		display: block;
	}
}

.wedding-nav.is-docked{
	position: fixed;
	bottom: auto;
	top: 0;
}

.wedding-nav ul{
	text-align: center;
	padding: 0;
	margin: 0;
}

.wedding-nav li{
	display: inline-block;
	list-style: none;
	padding: 0;
	margin: 0;
}

.wedding-nav a{
	color: white;
	text-transform: uppercase;
	font-family: 'Antonio', 'Montserrat', sans-serif;
	display: inline-block;
	padding: 17px 15px 15px 15px;
	font-size: 16px;
	line-height: 18px;
}

@media screen and (min-width: 1020px){
	.wedding-nav a{
		padding: 27px 25px 25px 25px;
		font-size: 22px;
		line-height: 22px;
	}
}

.wedding-nav a:hover, .wedding-nav a.is-active{
	color: #a4eae8;
}

.wedding-intro .inner{
	max-width: 820px;
	text-align: center;
}

.wedding-about .inner{
	max-width: 1140px;
}

.wedding-about-text{
	margin-bottom: 25px;
}

.wedding-about-images figure:first-of-type{
	margin-bottom: 20px;
}

@media screen and (min-width: 820px){
	.wedding-about-text{
		float: left;
		width: 50%;
		padding-left: 50px;
		margin-bottom: 0;
	}

	.wedding-about-images{
		float: left;
		width: 50%;
		padding-left: 50px;
	}

	.wedding-about-images figure:first-of-type{
		margin-bottom: 50px;
	}
}

.wedding-about-images img{
	float: none;
	width: 100%;
	height: auto;
	display: block;
}

.wedding-ceremonies .inner{
	max-width: 1140px;
}

.wedding-ceremonies-text{
	margin-bottom: 25px;
}

@media screen and (min-width: 820px){
	.wedding-ceremonies-text{
		float: left;
		width: 50%;
		padding-right: 50px;
		margin-bottom: 0px;
	}

	.wedding-ceremonies-images{
		float: left;
		width: 50%;
	}
}

.wedding-ceremonies-images img{
	float: none;
	width: 100%;
	height: auto;
	display: block;
}

.wedding-messages{
	background: #5ebfbb;
	box-sizing: border-box;
}

.wedding-messages *{
	box-sizing: border-box;
}

.wedding-message-icon{
	margin-bottom: 20px;
}

.wedding-message-icon img{
	width: auto;
	max-width: 30px;
	height: auto;
	display: block;
	float: none;
}

.wedding-message{
	color: white;
	padding: 20px 30px;
	text-align: left;
}

.wedding-message h1, .wedding-message h2, .wedding-message h3, .wedding-message h4{
	color: white;
	margin-bottom: 0;
}

.wedding-message h1, .wedding-message h2{
	font-family: 'Antonio', sans-serif;
	font-size: 28px;
	line-height: 34px;
}

.wedding-message h3, .wedding-message h4{
	font-family: 'Antonio', sans-serif;
	font-size: 20px;
	line-height: 24px;
}

.wedding-message:nth-child(2n){
	background: #19a39d;
}

.wedding-message-title{
	color: black;
	font-family: 'Antonio', sans-serif;
	font-weight: bold;
	font-size: 25px;
	line-height: 25px;
	margin-bottom: 20px;
}

@media screen and (min-width: 980px){
	.wedding-message h3, .wedding-message h4{
		font-size: 28px;
		line-height: 34px;
	}
	.wedding-message h1, .wedding-message h2{
		font-size: 36px;
		line-height: 42px;
	}
	.wedding-messages{
		display: flex;
		flex-flow: row no-wrap;
		align-content: center;
	}
	.wedding-message{
		flex: 1 1 0;
	}
	.wedding-message-icon img{
		max-width: 40px;
	}
}

@media screen and (min-width: 1200px){
	.wedding-message h1, .wedding-message h2{
		font-size: 42px;
		line-height: 60px;
	}
	.wedding-message h3, .wedding-message h4{
		font-size: 34px;
		line-height: 46px;
	}
	.wedding-message{
		padding: 60px 80px;
	}
	.wedding-message-icon img{
		max-width: 60px;
	}
}

.wedding-bellingen-hero{
	color: white;
	padding: 50px 10px 40px 10px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	text-align: center;
	position: relative;
}

@media screen and (min-width: 540px){
	.wedding-bellingen-hero{
		padding: 80px 10px 60px 10px;
	}
}

@media screen and (min-width: 860px){
	.wedding-bellingen-hero{
		padding: 150px 20px 120px 20px;
	}
}

.wedding-bellingen-hero h2{
	color: white;
	position: relative;
	z-index: 2;
	margin-bottom: 0;
}

.wedding-bellingen-hero::after{
	content: "";
	background: rgba(10,10,10,0.65);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
}

.wedding-bellingen .inner{
	max-width: 1000px;
	text-align: center;
}

.wedding-half-images figure{
	width: 100%;
	margin: 0;
	padding: 0;
}

@media screen and (min-width: 600px){
	.wedding-half-images figure{
		width: 50%;
		float: left;
	}
}

.wedding-half-images img{
	width: 100%;
	height: auto;
	display: block;
	float: none;
}

.wedding-receptions .inner{
	max-width: 1000px;
	text-align: center;
}

.wedding-receptions img{
	float: none;
	margin: 10px 5px 0px 5px;
}

.wedding-receptions h3{
	font-family: 'Antonio', sans-serif;
	margin-bottom: 5px;
	font-size: 20px;
	line-height: 20px;
	margin-top: 30px;
}

@media screen and (min-width: 600px){
	.wedding-receptions h3{
		font-size: 26px;
		line-height: 26px;
		margin-top: 50px;
	}
}

.wedding-kit{
	padding: 40px 10px 50px 10px;
	text-align: center;
}

.wedding-kit h3{
	color: black;
	font-family: 'Antonio', sans-serif;
	letter-spacing: 0.1em;
	font-size: 20px;
	line-height: 20px;
}

.wedding-kit h2{
	color: black;
	font-family: 'Antonio', sans-serif;
	font-size: 26px;
	line-height: 26px;
	margin-bottom: 30px;
	font-weight: bold;
}

.wedding-kit h2 em{
	font-family: 'Playfair Display', serif;
	text-transform: none;
	font-weight: normal;
	font-size: 60px;
}

@media screen and (min-width: 640px){
	.wedding-kit{
		padding: 100px 20px 120px 20px;
	}

	.wedding-kit h3{
		letter-spacing: 0.1em;
		font-size: 28px;
		line-height: 28px;
	}

	.wedding-kit h2{
		font-size: 58px;
		line-height: 58px;
		margin-bottom: 40px;
	}

	.wedding-kit h2 em{
		font-size: 120px;
	}
}

.wedding-testimonials{
	padding-left: 0;
	padding-right: 0;
}

.wedding-testimonials .owl-carousel{
	position: relative;
	background: #010a11;
}

.wedding-testimonials .item{
	color: white;
	max-width: 1140px;
	margin: 0 auto;
	text-align: center;
	font-family: 'Playfair Display', serif;
	padding: 40px 15px 60px 15px;
	font-size: 20px;
	line-height: 28px;
}

.wedding-testimonials h4{
	font-size: 14px;
	line-height: 18px;
	font-weight: 700;
	font-family: 'Open Sans', sans-serif;
	color: white;
	text-transform: none;
	padding-top: 15px;
}

.wedding-testimonials .owl-dots{
	bottom: 20px;
}


@media screen and (min-width: 710px){
	.wedding-testimonials .item{
		padding: 80px 20px 120px 20px;
		font-size: 26px;
		line-height: 38px;
	}
	.wedding-testimonials h4{
		font-size: 18px;
		line-height: 20px;
		padding-top: 20px;
	}
	.wedding-testimonials .owl-dots{
		bottom: 50px;
	}
}

.wedding-gallery .owl-carousel .item{
	padding-top: 60%;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
}

.wedding-galleries{
	padding-top: 20px;
	padding-bottom: 0px;
}

@media screen and (min-width: 710px){
	.wedding-galleries{
		padding-top: 40px;
		padding-bottom: 100px;
	}
}

.wedding-galleries .owl-nav{
	position: absolute;
	top: -33px;
	left: 50%;
	transform: translate3d(-50%,0,0) scale(0.65);
}

.wedding-galleries .owl-nav div{
	width: 66px;
    height: 66px;
    margin-right: 1px;
    background-repeat: no-repeat;
    background-position: top left;
    display: block;
    float: left;
    overflow: hidden;
    text-indent: -9999px;
    transition: none;
}

.wedding-galleries .owl-nav div:hover{
	background-position: bottom left;
}

.wedding-galleries .owl-next{
	background-image: url(../images/slide-right-button.png);
}

.wedding-galleries .owl-prev{
	background-image: url(../images/slide-left-button.png);
}

@media screen and (min-width: 1060px){
	.wedding-gallery .owl-carousel{
		float: left;
		width: 50%;
	}
	.wedding-gallery-2{
		top: 60px;
	}
	.wedding-galleries .owl-nav{
		top: 0;
		left: 0;
		transform: none;
	}
}

.form-confirmation{
	max-width: 530px;
	margin: 0 auto;
	padding: 20px;
	text-align: center;
	font-weight: bold;
	color: white;
	margin-bottom: 80px;
}

.form-confirmation.is-success{
	background: #19a39d;
}

.form-confirmation.is-error{
	background: #d85836;
}

.download-form{
	max-width: 480px;
	margin: 0 auto;
}

.download-form .input-wrapper{
	float: none;
	text-align: left;
	margin-bottom: 15px;
}

.g-recaptcha > div{ 
	margin: 20px auto 0 auto;
}

.mycss_reservation.error{ 
	background: #d85836; color: white; padding: 10px;
}